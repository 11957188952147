import { createRouter, createWebHistory } from 'vue-router';
import {central_data} from '@/lib/vendor/eve/central-data';

const routes = [

    //<editor-fold defaultstate="collapsed" desc="mod-grant">    
    {
        path: '/grant/:alias',
        name: 'main',
        props: true,
        component: () => import(/* webpackChunkName: "mod-main" */ '@/components/main-p')

    },

    //</editor-fold>

    {
        path: '/:catch(.*)',
        component: () => {
            return import('@/components/p-404');
        },
        props: true
    }
];
var route_id = 0;
const named_routes = routes.map(function (x) {
    if (!x.name) {
        x.name = ["rt", route_id].join('');
        route_id++;
    }
    return x;
});

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: named_routes
});
router.beforeResolve(function (a, b, c) {
    //console.log(arguments);
    c();
});


function reloadRoutes() {
    for (var iRoute = 0; iRoute < named_routes.length; iRoute++) {
        router.removeRoute(named_routes[iRoute].name);
    }
    for (var aRoute = 0; aRoute < named_routes.length; aRoute++) {
        router.addRoute(named_routes[aRoute]);
    }
    console.log('routes reloaded');
    //router.go();
    //console.log('force reload');
}

central_data().ready(function () {
    window.xxrouter = router;
    central_data().jwtMonitor.on({}, reloadRoutes);


});

export default router

<template>      
    <router-view />    
</template>

<style lang="scss">
    #app {
        width: 100%;
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
    }

</style>
<script>
    //import {central_data} from '@/lib/vendor/eve/central-data';
    //import U from '@/lib/vendor/eve/lib-utils';
    export default{
        name: 'app-main',
        data: function () {
            return{
            };
        },
        methods: {

        },
        created: function () {
            //
        },
        unmounted: function () {
            //
        },
        mounted: function () {
            //
        }

    }
</script>
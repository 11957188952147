import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import {central_data} from '@/lib/vendor/eve/central-data';
import U from '@/lib/vendor/eve/lib-utils';
try {
    if (U.isMobile()) {
        var bds = document.getElementsByTagName('body');
        if (bds && bds.length) {
            bds.item(0).classList.add('mobile-view');
        }
    }
} catch (e) {
    console.log(e);
}
central_data().ready(function () {
    axios.interceptors.response.use(function (response) {
        if (response.status === 401) {
            if (!/^\/login/.test(router.currentRoute.value.path)) {
                router.replace({path: '/login', query: {ret: router.currentRoute.value.fullPath}});
                return null;
            } else {
                response.data.status = 'error';
            }
        } else if (U.isObject(response.data)) {
            if (response.data.status === 'auth') {
                if (!/^\/login/.test(router.currentRoute.value.path)) {
                    router.replace({path: '/login', query: {ret: router.currentRoute.value.fullPath}});
                    return null;
                } else {
                    response.data.status = 'error';
                }
            }
        }
        //  console.log('login-incept:',response);
        return response;
    }, function (error) {
        if (401 === error.response.status) {
            if (!/^\/login/.test(router.currentRoute.value.path)) {
                router.replace({path: '/login', query: {ret: router.currentRoute.value.fullPath}});
                return;
            }

        }
        return Promise.reject(error);
    });
    
    createApp(App).use(router).use(central_data().translator).mount('#app');
});

